import * as React from 'react';
import { Link, graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  LRestaurantPickup,
  LRestaurantPlan,
  CHeroImg,
  CDefinition,
  LRestaurant,
  CPanelCard,
  LReservation,
  CLocalNav,
  CBtnList,
  CSectTitle,
  CPickup,
  ShopContents,
  LRestaurantContact,
} from '../../../components/_index';
import pickupGet from '../../../utils/pickup-get';
import { pickupRestaurantsChoice } from '../../../utils/pickup-choice';
import shopGet from '../../../utils/shop-get';
import { shopFixedChoice } from '../../../utils/shop-choice';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="large"
        data={{
          title: {
            main: 'PRIVATE ROOM',
            sub: '個室のご案内',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/restaurants/private/kv.png',
              },
              imgSp: {
                src: '/assets/images/restaurants/private/kv__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [
              {
                label: 'レストラン&バー',
                path: '/restaurants/',
              },
            ],
            current: {
              label: '個室のご案内',
            },
          }}
        />
      </CJumbotron>
      <div className="l_sect02">
        <LWrap>
          <CLocalNav
            data={[
              {
                img: {
                  src: '/assets/images/restaurants/private/img_localnav.png',
                  alt: '',
                },
                link: {
                  href: '#leciel',
                },
                title: {
                  main: 'ル シエール',
                  sub: '68F フレンチレストラン',
                },
              },
              {
                img: {
                  src: '/assets/images/restaurants/private/img_localnav02.png',
                  alt: '',
                },
                link: {
                  href: '#shiki',
                },
                title: {
                  main: '四季亭',
                  sub: '68F 日本料理',
                },
              },
              {
                img: {
                  src: '/assets/images/restaurants/private/img_localnav03.png',
                  alt: '',
                },
                link: {
                  href: '#kohen',
                },
                title: {
                  main: '皇苑',
                  sub: '68F 中国料理',
                },
              },
            ]}
          />
        </LWrap>
      </div>
      <section className="u_mbLarge" id="leciel">
        <LWrap>
          <h2 className="c_headingLv2">
            68F フレンチレストラン
            <br className="u_sp" />
            「ル シエール」
          </h2>
          <ShopContents
            data={shopFixedChoice(shopGet(), 'leciel_private_room').contents1}
          />
          <LRestaurantPickup
            restaurant={['ル シエール']}
            classification={['個室プラン']}
            name="leciel"
          />
        </LWrap>
      </section>
      <section className="u_mbLarge" id="shiki">
        <LWrap>
          <h2 className="c_headingLv2">68F 日本料理「四季亭」</h2>
          <ShopContents
            data={shopFixedChoice(shopGet(), 'shiki_private_room').contents1}
          />
          <LRestaurantPickup
            restaurant={['四季亭']}
            classification={['個室プラン']}
            name="shiki"
          />
        </LWrap>
      </section>
      <section className="u_mbLarge" id="kohen">
        <LWrap>
          <h2 className="c_headingLv2">68F 中国料理「皇苑」</h2>
          <ShopContents
            data={shopFixedChoice(shopGet(), 'kohen_private_room').contents1}
          />
          <LRestaurantPickup
            restaurant={['皇苑']}
            classification={['個室プラン']}
            name="kohen"
          />
          <ul className="c_noteList">
            <li>表示価格にはサービス料・税金が含まれます。</li>
          </ul>
        </LWrap>
      </section>
      <section>
        <LRestaurant />
      </section>
      <section className="l_sect">
        <LWrap>
          <ul className="c_noteList">
            <li>画像は全てイメージです。</li>
            <li>料金には消費税、サービス料が含まれます。</li>
            <li>料理の表示価格は、特別な記載がない限り1名様分の料金です。</li>
            <li>
              テイクアウト商品の料金には消費税（8％）のみ含まれます。コフレ店内をご利用の場合は、サービス料を加算、消費税は10％となります。
            </li>
            <li>入荷状況によりメニュー内容に変更がある場合がございます。</li>
            <li>
              「神奈川県公共的施設における受動喫煙防止条例」に基づきレストラン・バーを全席禁煙とさせていただいております。
            </li>
            <li>
              ご入店の際は、タンクトップ・短パン・ビーチサンダルなどはご遠慮いただいております。
            </li>
          </ul>
        </LWrap>
      </section>
      <LRestaurantContact />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
